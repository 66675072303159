@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
/* Variáveis*/
:root {
  --colorDefault: #09c4a9;
  --colorDefaultHover: #067c75; /*#098271;*/
  --secondColor: #5bffe7;
  --thirdColor: #fff;
  --colorText: #4b4b4b;
  --colorText2: #919191;
  --backgroundDefault: #f8f8f8;
  --shadow: #dbdbdb;
}

[data-theme="dark"] {
  --backgroundDefault: #252525;
  --colorText: #fff;
  --shadow: #0b0b0b;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: var(--colorDefault); }

@font-face {
  font-family: 'Font-Regular';
  font-size: 18px;
  src: local('Font-Regular'), url(./assets/fonts/Baumans-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "Font-Bold";
  src: local('Font-Bold'), url(./assets/fonts/Baumans-Regular.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Chewy-Regular';
  src: local('Chewy-Regular'), url(./assets/fonts/Chewy-Regular.ttf) format('truetype');
}

.color-primary {
  color: var(--colorDefault);
}
.bg-primary {
  background-color: var(--colorDefault);
}
.color-second {
  color: var(--secondColor);
}
.bg-second {
  background-color: var(--secondColor);
}
.color-third {
  color: var(--thirdColor);
}
.bg-third {
  background-color: var(--thirdColor);
}

html, body, #root {
  min-height: 100%;;
  font-family: 'Font-Regular';
}

body {
  background: var(--backgroundDefault);
  color: var(--colorText);
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Font-Bold';
}
ul {
  list-style: none;
}
p {
  font-size: 18px;
  color: var(--colorText);
}
a {
  color: var(--colorDefault);
}
a:hover {
  text-decoration: none;
  color: var(--colorDefaultHover);
}
span {
  color: var(--colorText);
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
  --webkit-box-shadow: none !important;
}
.btn-input {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -68px;
  margin-right: 25px;
  background: white;
  /* padding: 5px; */
  border-radius: 50%;
  width: 45px;
  height: 45px;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  --webkit-box-shadow: 0px 0px 5px -2px #d3d3d3;
}
.btn-input i {
  font-size: 1.2em !important;
}
/*body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}*/
.form-control {
  /*padding-top: 25px;*/
  font-family: 'Font-Regular';
  padding-top: 23px;
  height: 58px;
}
.form-control:focus {
  color: var(--colorText);
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
  --webkit-box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  font-family: 'Font-Regular';
  color: var(--colorText);
  font-weight: bold;
  margin-bottom: 8px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  z-index: 9;
  /*font-size: 12px;*/
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
}
.btn-small {
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 8px 12px;
}
.btn-default {
  background: var(--colorDefault);
  color: #fff;
}
.btn-default:hover {
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-default-outline {
  padding: 15px 22px;
  border-radius: 30px;
  background: var(--backgroundDefault);
  border: 2px solid var(--colorDefault);
  color: var(--colorDefault);
}
.btn-default-outline:hover {
  background: var(--colorDefault);
  color: #fff;
  /*box-shadow: 0px 0px 10px 1px #656565;*/
  border: 2px solid #fff;
}
.btn-delete {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-delete:hover {
  background: #1d1d1d;
  border-color: #1d1d1d;
  color: #fff;
}
.btnLink {
  color: var(--colorDefault);
  cursor: pointer;
  border: 0;
  background: transparent;
}
.btnCloseModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: #2b2b2b;
  color: var(--colorDefault);
  padding: 5px;
  font-size: 25px;
  border: 0;
  background: transparent;
}
.btn-outline {
  background-color: transparent;
  border: 1px solid var(--colorDefault);
  color: var(--colorDefault);
}
.hidden {
  display: none;
}

.v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 9999;
  background: rgba(250, 250, 250, 0.85);
  flex: 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* TABS */
.tabs {
  -webkit-tap-highlight-color: transparent;
}
.tabs .tabMenu {
  border-bottom: 1px solid #d1d1d1;
  margin: 0 0 10px;
  padding: 0;
}
.tabs .tabMenuItem {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
}
.tabs .tabMenuItem.active {
  background: var(--backgroundDefault);
  border-color: #d1d1d1;
  box-shadow: 0px -5px 15px -8px rgb(210 210 210);
  color: black;
  border-radius: 5px 5px 0 0;
}
.tabs .tabMenuItem:focus {
  outline: none;
}
.tabs .tabMenuItem:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}
.tabs .tabMenuItem i{
  margin-right: 8px;
}

/* Gallery */
.image-gallery-image{
  border-radius: 10px;
}
.image-gallery .image-gallery-slides{
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
  border-radius: 10px;
  margin-bottom: 5px;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 40px !important;
  width: 40px !important;
  color: var(--colorDefault);
}
.image-gallery-content.fullscreen {
  display: inherit !important;
}
.image-gallery-slide .image-gallery-description {
  bottom: 0px !important;
}
.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid var(--colorDefault) !important;
}
.image-gallery-icon {
  color: var(--colorDefault) !important;
  filter: none !important;
}
.image-gallery-icon:focus {
  outline: none !important;
}
.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid var(--colorDefault) !important;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #f8f8f8;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all .2s ease-out;
}
.image-gallery-bullets .image-gallery-bullet.active {
  border: 1px solid var(--colorDefault);
  background: var(--colorDefault);
}
.image-gallery-bullets .image-gallery-bullet:hover {
  background: var(--colorDefault);
  border: 1px solid var(--colorDefault);
}
@media (hover: hover) and (pointer: fine){
  .image-gallery-bullets .image-gallery-bullet.active:hover {
      background: var(--colorDefault);
  }
}

/*DATA PICKER */
.react-datepicker-popper {
  z-index: 99 !important;
}

/*TABLE */
.table {
  width: 100%;
  text-align: center;
  max-height: 280px;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 0px 10px 20px -5px #e4e4e4;
  --webkit-box-shadow: 0px 10px 20px -5px #e4e4e4;
}

.link {
  font-family: 'Font-Regular';
  font-weight: 600;
  color: var(--colorDefault);
  cursor: pointer;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch-absolute {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  right: 0;
  margin-right: 12px;
  margin-top: -8px;
  z-index: 99;
}
.switch input, .switch-absolute input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} 
input[type=checkbox]{
  cursor: pointer;
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  cursor: pointer;
  /* filter: invert(100%) hue-rotate(112deg) brightness(1.1); */
  filter: hue-rotate(315deg);
}

.hidden-xs, .hidden-sm, .hidden-md {
  display: initial;
} 
.visible-xs, .visible-sm, .visible-md {
  display: none;
} 

.divBtnInstallApp {
  width: 100%;
  max-width: 600px;
  background: #f8f8f8;
  padding: 12px 10px 5px 10px;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px -2px 18px -2px #c0c0c0;
  --web-kit-box-shadow: 0px -2px 18px -2px #c0c0c0;
  transition: ease 2s;
  z-index: 9;
}
.divBtnInstallApp p {
  margin-bottom: 0px;
  font-size: 12px;
}
.divBtnInstallApp h5 {
  margin-bottom: 5px;
}
.divBtnInstallApp .btnCloseInstallApp {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  color: #636363;
  background: transparent;
  z-index: 8;
  padding: 5px;
  margin: 2px 5px;
  font-size: 16px;
}

.shadowProduct {
  box-shadow: rgb(251 251 251) 0px 50px 50px 2px;
  width: 100%;
  max-width: 60%;
  margin-left: 25%;
}

/* INPUTS RANGE'S */
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    min-width: 140px;
    -webkit-appearance: none;
    background-color: #909090;
    cursor: pointer;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: var(--colorDefault);
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    background: var(--colorDefaultHover);
    box-shadow: -140px 0 0 140px var(--colorDefault);
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: var(--colorDefault);
}
input[type="range"]::-moz-range-track {  
  background-color: #909090;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
  background-color:#909090;
}

/* Tiny */
.tox.tox-tinymce{
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  border: 0;
}

/* XS Mobile Layout */
@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: initial;
  }
  h1 {
    font-size: 2.3rem;
  }
  p {
    font-size: 16px;
  }
  .btn {
    font-size: 16px;
  }
}

/* SM - Tablet Layout */
@media only screen and (min-width: 768px) and (max-width: 991px){
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: initial;
  }
}

/* MD Layout */
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: initial;
  }
}